
.Close {
  cursor: pointer;
  color: white;
  margin: 0;
  font-size: 20px;
  padding: 4px 8px;
}

.Close:hover {
  color: black;
}

