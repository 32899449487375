.Container {
    width: 100%;
    background: #828282;
    margin: 0 0 2px 0;
    padding: 0;
    cursor: pointer;
}

.Container:hover {
    background: #ffffff;
    margin: 0 0 0 0;
    border-bottom: 2px solid red;
}

.Container:hover div {
    color: red;
}

.Container div {
    width: 100%;
    margin: 0;
    padding: 10px;
    text-decoration: none;
    color: white;
}

.active {
    background: #ffffff;
    margin: 0 0 0 0;
    border-bottom: 2px solid red;
}

.active div {
    color: red;
}

