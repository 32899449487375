.Part {
  font-size: 0.8rem;
}

.Relative {
  position: relative;
  padding: 2px 0;
}

.Flex {
  display: -webkit-flex;
  display: flex;
}

.Tall {
  line-height: 30px;
}

.Full {
  width: 100%;
}

.TitleCol {
  text-align: left;
  flex-grow: 1;
  overflow: hidden;
}

.SizeCol {
  flex: 0 0 50px;
  text-align: right;
}

.ProgressCol,
.StatusCol {
  flex: 0 0 100px;
  text-align: right;
  padding-right: 5px;
}

.StatusPending {
  color: blue;
}

.StatusUploading,
.StatusUploaded,
.StatusConverting,
.StatusImporting {
  font-style: italic;
  color: green;
}

.StatusFinished {
  color: grey;
}

.StatusError {
  color: red;
}

.Progress {
  font-size: 12pt;
  background-color: blue;
}

.Progress .bar {
  background-color: red;
  background-color: red;
}

.RetryContainer {
  width: 35px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Retry {
  border-radius: 4px;
  background: #F44336;
  cursor: pointer;
  color: #FFFFFF;
  margin: 0;
  font-size: 14px;
  padding: 6px 10px;
}

.Retry:hover {
  color: #212121;
  background: #D32F2F;
}

