.Container {
    position:relative;
}

.VideoInfo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 2147483647;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    color: white;
    display: flex;
    opacity: 0;
}

.VideoInfo div {
    display: block;
    margin: 5px;
    padding: 5px;
    background: red;
    color: white;
}

.Container:fullscreen .VideoInfo {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
}

.Container:fullscreen {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Container:fullscreen .Video-Info:hover {
    background: rgba(0, 0, 0, 1);
    color: white;
}

.ProgressContainer {
    z-index: 999; /* 2147483647 */
    width: 100%;
    height: 35px;
    position: absolute;
    bottom:-31px;
    left:0;
    background: black;
}

.ProgressContainer2 {
    z-index: 999; /* 2147483647 */
    width: 100%;
    height: 28px;
    position: absolute;
    bottom:-59px;
    left:0;
    background: black;
}

.Progress {
    z-index: 1000 !important; /* 2147483647 */
    border-radius: 0;
    cursor: pointer;
    background: black;
}

.ProgressBar {
    margin-top: 0;
    text-align: left;
    transition-duration: 0.05s;
    height: 35px;
    background-color: red;
}

.Controls {
    position: absolute;
    bottom: 0px;
    left: 0;
    text-align: left;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
}

.ControlIcons {
    display:flex; 
    flex-grow: 0;
}

.VideoIndex {
    display:flex; 
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
}

.ControlTime {
    display:flex; 
    justify-content: flex-end;
    align-items: center;
    padding: 0 5px;
    width: 150px;
}

.VideoIndex i, .ControlIcons i {
    font-size: 20px;
}

.VideoIndex div, .ControlIcons div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 8px 4px 8px;
}

.Figure {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.Video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.Video::-webkit-media-controls {
    display: none !important;
}

.IconTextContainer {
    position: relative;
}

.IconText {
    font-size: 0.6rem;
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 8px;
}

.SelectedInput {
    width: 25px;
    text-align: center;
}

.SelectedInput:focus {
    outline: none;
}