.Menu {
    padding-right: 15px;
    display: flex;
    flex-direction: column;
}

.Content {
    padding: 0 10px 0 0;
    border-right: 1px solid #BDBDBD;
    flex: 1 1 auto;
}

.Legend {
    margin: 15px 0 0 0;
}

.LegendRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.LegendColor {
    width: 20px;  
    margin-top: -3px;
    margin-left: 0;
}

.LegendColor div {
    height: 10px;
    border-radius: 3px;
}

.LegendLabel {
    flex: 1;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 10px;
    font-size: 0.9rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.00938em;
}

.LegendValue {
    width: 100px;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: rgba(0, 0, 0, 1);
    padding: 0;
    font-size: 0.9rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.00938em;
    text-align: right;
}