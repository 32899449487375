.Create {
    margin: 10px;
    padding: 0;
    cursor: pointer;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    border-radius: 4px;
    background: #fff;
    color: rgba(0, 0, 0, .87);
}

.CreateContent {
    display: flex;
    padding: 0;
}

.Triangle {
    border: 54px solid transparent;
}

.Menu ul {
    padding: 10px 0;
    background: #9E9E9E;
    width: 250px;
}

.NavLink {
    text-decoration: none;
}

.NavLink div {
    color: white;
    width: 100%;
    padding: 5px;
    margin: 0px;
    font-size: 0.7rem;
    border-radius: 3px;
}

.NavLink div:hover {
    color: #F5F5F5;
    background: red;
}

.Active div {
    color: #F5F5F5;
    background: #757575;
}

.Team {
    cursor: pointer;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold
}

.Team:hover {
    color: #fff;
    background: red;
}

.Season {
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    padding: 3px 3px 3px 10px;
    margin-bottom: 0;
    font-size: 0.7rem;
}

.Season:hover {
    color: #fff;
    background: red;
}

.MenuContainer {
    position: relative;
}

.SeasonMenu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2147483647;
    display: none;
    width: 0;
    height: 0;
    background: #555555;
    border: 2px blue;
    border-radius: 5px;
}

.SeasonMenuActive {
    display: block;
    width: 180px;
    height: auto;
    padding: 5px;
}

.SeasonMenu .NavLink div, .SeasonMenuActive .NavLink div {
    margin-left: 0px;
    width: 170px;
}

.Profile {
    background: #36454F;
    display: flex;
    padding: 5px;
}

.Profile:hover {
    background: #414A4C;
}

.Profile .Link {
    width: 100%;
}

.Profile .Link:hover {
    color: red;
}

.SubProfile {
    padding: 10px;
}

.SubProfile .Link {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}

.SubProfile .Link:hover {
    color: red;
}

.ProfileName {
    font-size: 0.8rem;
    font-style: normal;
}

.ProfileUsername {
    font-size: 0.8rem;
    font-style: normal;
}

.ProfileEmail {
    font-size: 0.8rem;
    font-style: normal;
}

.ProfileLink {
    font-size: 0.6rem;
    font-style: normal;
}

.ProfileImage {
    padding: 5px;
}

.ProfileImage img {
    width: 40px;
    border-radius: 50%;
}

.TeamContainer {
    background: #000000;
    padding: 5px;
    margin-bottom: 5px;
    height: 55px;
}

.TeamContainer .Link {
    width: 100%;
}

.TeamContainer .Link:hover {
    color: red;
}

.SubTeamContainer {
    padding: 10px;
}

.SubTeamContainer .Link {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}

.SubTeamContainer .Link:hover {
    color: red;
}

.Container {
    cursor: pointer;
    display: flex;
    padding: 5px;
}

.Container .Link {
    width: 100%;
}

.Name {
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.Username {
    color: white;
    font-size: 0.8rem;
    font-style: italic;
    padding-left: 5px;
}

.SeasonOverlay {
    position: absolute;
    top:0;
    left:0;
    background: red;
    opacity: 0.01;
    width: 100vw;
    height: 100vh;
    z-index: 500;
}