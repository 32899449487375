@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    background: #ccc;
    width: 100%;
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.MuiDialogTitle-root {
    background: lightgray;
    border-bottom: 1px solid black;
}

.MuiDialogContent-root {
    background: white;
    border-bottom: 1px solid black;
}

.MuiDialogActions-root {
    background: lightgray;
    border-top: 1px solid black;
}

.DialogAction {
    cursor: pointer;
    color: black;
    margin: 0;
    font-size: 20pt;
    padding: 10px;
    border-radius: 50%;
}

.PageAction {
    cursor: pointer;
    color: white;
    padding: 3px;
    margin: 0;
}

.EditCursor {
    cursor: pointer;
}

.Circle {
    border-radius: 50%;
}

.PrimaryTable {
    background: #607D8B;
}

.PrimaryTable .MuiTableCell-head {
    color: #FFFFFF;
}

.PrimaryTableFooter {
  background: #BDBDBD;
}

.PrimaryTableFooter .MuiTableCell-root {
    color: #000000;
}

.Hot {
    cursor: pointer;
}

#cardForm {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .CardForm {
      border: 10px solid hotpink;
  }
  
  
  /*--------------------
  PANEL FORM
  --------------------*/
  
  .panel {
    background: #fff;
    width: 80%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  }
  
  .panel__header {
    background: #3F51B5;
    color: #fff;
  }
  
  .panel__header,
  .panel__footer {
    padding: 1em 2em;
  }
  
  .panel__footer {
    background: #f3f3f3;
  }
  
  .panel__content {
    padding: 1em 2em;
    overflow: hidden;
  }
  
  .textfield--float-label {
    width: 50%;
    float: left;
    display: inline-block;
    padding-right: 5px;
  }
  
  .hosted-field--label {
    transform: translateY(0.4em);
    font-size: 1.125em;
    line-height: 32px;
    transition: all .15s ease-out;
    display: block;
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 0.5em;
  }
  
  .hosted-field--label .label-float {
    text-overflow: ellipsis;
    color: #2196F3;
    transition: all .15s ease-out;
  }
  .hosted-field--label .filled {
    text-overflow: ellipsis;
    color: #2196F3;
    transition: all .15s ease-out;
    color: rgba(0, 0, 0, .54);
  }
  
  .hosted-field--label .invalid {
    text-overflow: ellipsis;
    color: #2196F3;
    transition: all .15s ease-out;
    color: #F44336;
  }
  
  
  span.icon {
    position: relative;
    top: 0.2em;
    margin-right: 0.2em;
  }
  
  svg {
    fill: #333;
  }
  
  .hosted-field {
    height: 32px;
    margin-bottom: 1em;
    display: block;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative;
  }
  
  .pay-button {
    background: #E91E63;
    color: #fff;
    margin: 0 auto;
    border: 0;
    border-radius: 3px;
    padding: 1em 3em;
    font-size: 1em;
    text-transform: uppercase;
    box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 2px 2px rgba(0, 0, 0, .2);
  }
  
  
  /*--------------------
  BT HOSTED FIELDS SPECIFIC 
  --------------------*/
  
  .braintree-hosted-fields-focused {
    border-bottom: 2px solid #3F51B5;
    transition: all 200ms ease;
  }
  
  .braintree-hosted-fields-invalid {
    border-bottom: 2px solid #E91E63;
    transition: all 200ms ease;
  }
  
  
  /*---------------------
  Media Queries
  ----------------------*/
  
  @media (max-width: 600px) {
    html {
      overflow: auto;
    }
    #cardForm {
      height: auto;
      margin: 2em;
      font-size: 13px;
    }
    .panel {
      width: 100%;
    }
    .textfield--float-label {
      width: 100%;
      float: none;
      display: inline-block;
    }
    .pay-button {
      width: 100%;
    }
  }

  .TrashIconButton {
    border-radius: 4px;
    background: #F44336;
    cursor: pointer;
    color: #FFFFFF;
    margin: 0 0 0 2px;
    font-size: 0.8rem;
    padding: 6px 10px;
  }
  
  .TrashIconButton:hover {
    color: #212121;
    background: #D32F2F;
  }
  
  