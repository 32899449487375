.Container {
    margin: 10px 0;
    padding: 10px 0;
    display: flex;
}

.Row {
    flex: 1 1 0px;
}

.Cell {
    flex: 1 1 0px;
}