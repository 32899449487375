.Outer {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.Inner {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    height: 1px;
    position: relative;
    padding: 0;
}

.AddTeam {
    text-align: center;
    margin: 3px;
    margin-top: 15px;
    cursor: pointer;
    padding: 2px;
    background-color: #757575;
    color: #F5F5F5;
}

.AddTeam:hover {
    background-color: #F5F5F5;
    color: #757575;
}
