.ColorPicker {
    background: #fff;
    text-align: center;
    padding: 10px 0;
}

.Label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;    color: rgba(0, 0, 0, 0.54);
    padding: 0 10px;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.00938em;
}


.color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 2;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@media (min-width: 500px) {
    .ColorPicker {
        width: 400px;
    }
}
