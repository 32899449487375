.Table th {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 3px;
}

.Table td {
    font-size: 0.7rem;
    margin: 0;
    padding: 3px;
}

.Close {
    border-radius: 4px;
    background: #f44336;
    padding: 0;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
}