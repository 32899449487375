.Buttons {
}

.Buttons Button {
    margin: 0 2px;
}

@media (min-width: 500px) {
    .ProfileItems {
        flex-flow: row;
    }
}
