.Error {
    width: 95%;
    border: 1px solid darkred;
    box-shadow: 0 2px 3px darkred;
    background: red;
    color: white;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
}
