.Alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    box-sizing: border-box;
}

.Red {
    border: 1px solid #f5c6cb;
    color: #721c24;
    background-color: #f8d7da;
}

.Green {
    border: 1px solid #c3e6cb;
    color: #155724;
    background-color: #d4edda;
}

