@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
html, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    background: #ccc;
    width: 100%;
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.MuiDialogTitle-root {
    background: lightgray;
    border-bottom: 1px solid black;
}

.MuiDialogContent-root {
    background: white;
    border-bottom: 1px solid black;
}

.MuiDialogActions-root {
    background: lightgray;
    border-top: 1px solid black;
}

.DialogAction {
    cursor: pointer;
    color: black;
    margin: 0;
    font-size: 20pt;
    padding: 10px;
    border-radius: 50%;
}

.PageAction {
    cursor: pointer;
    color: white;
    padding: 3px;
    margin: 0;
}

.EditCursor {
    cursor: pointer;
}

.Circle {
    border-radius: 50%;
}

.PrimaryTable {
    background: #607D8B;
}

.PrimaryTable .MuiTableCell-head {
    color: #FFFFFF;
}

.PrimaryTableFooter {
  background: #BDBDBD;
}

.PrimaryTableFooter .MuiTableCell-root {
    color: #000000;
}

.Hot {
    cursor: pointer;
}

#cardForm {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .CardForm {
      border: 10px solid hotpink;
  }
  
  
  /*--------------------
  PANEL FORM
  --------------------*/
  
  .panel {
    background: #fff;
    width: 80%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
  }
  
  .panel__header {
    background: #3F51B5;
    color: #fff;
  }
  
  .panel__header,
  .panel__footer {
    padding: 1em 2em;
  }
  
  .panel__footer {
    background: #f3f3f3;
  }
  
  .panel__content {
    padding: 1em 2em;
    overflow: hidden;
  }
  
  .textfield--float-label {
    width: 50%;
    float: left;
    display: inline-block;
    padding-right: 5px;
  }
  
  .hosted-field--label {
    transform: translateY(0.4em);
    font-size: 1.125em;
    line-height: 32px;
    transition: all .15s ease-out;
    display: block;
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 0.5em;
  }
  
  .hosted-field--label .label-float {
    text-overflow: ellipsis;
    color: #2196F3;
    transition: all .15s ease-out;
  }
  .hosted-field--label .filled {
    text-overflow: ellipsis;
    color: #2196F3;
    transition: all .15s ease-out;
    color: rgba(0, 0, 0, .54);
  }
  
  .hosted-field--label .invalid {
    text-overflow: ellipsis;
    color: #2196F3;
    transition: all .15s ease-out;
    color: #F44336;
  }
  
  
  span.icon {
    position: relative;
    top: 0.2em;
    margin-right: 0.2em;
  }
  
  svg {
    fill: #333;
  }
  
  .hosted-field {
    height: 32px;
    margin-bottom: 1em;
    display: block;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .26);
    outline: 0;
    width: 100%;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative;
  }
  
  .pay-button {
    background: #E91E63;
    color: #fff;
    margin: 0 auto;
    border: 0;
    border-radius: 3px;
    padding: 1em 3em;
    font-size: 1em;
    text-transform: uppercase;
    box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 2px 2px rgba(0, 0, 0, .2);
  }
  
  
  /*--------------------
  BT HOSTED FIELDS SPECIFIC 
  --------------------*/
  
  .braintree-hosted-fields-focused {
    border-bottom: 2px solid #3F51B5;
    transition: all 200ms ease;
  }
  
  .braintree-hosted-fields-invalid {
    border-bottom: 2px solid #E91E63;
    transition: all 200ms ease;
  }
  
  
  /*---------------------
  Media Queries
  ----------------------*/
  
  @media (max-width: 600px) {
    html {
      overflow: auto;
    }
    #cardForm {
      height: auto;
      margin: 2em;
      font-size: 13px;
    }
    .panel {
      width: 100%;
    }
    .textfield--float-label {
      width: 100%;
      float: none;
      display: inline-block;
    }
    .pay-button {
      width: 100%;
    }
  }

  .TrashIconButton {
    border-radius: 4px;
    background: #F44336;
    cursor: pointer;
    color: #FFFFFF;
    margin: 0 0 0 2px;
    font-size: 0.8rem;
    padding: 6px 10px;
  }
  
  .TrashIconButton:hover {
    color: #212121;
    background: #D32F2F;
  }
  
  
.Ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: Ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: Ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.Ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes Ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@keyframes Ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.Alert_Alert__2H3zo {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    box-sizing: border-box;
}

.Alert_Red__2aj3Y {
    border: 1px solid #f5c6cb;
    color: #721c24;
    background-color: #f8d7da;
}

.Alert_Green__EMOmu {
    border: 1px solid #c3e6cb;
    color: #155724;
    background-color: #d4edda;
}


.CardHeader {
    text-align: center;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid red;
    width: 100%;
    font-size: 2em;
    font-weight: bold;
}

.Card_Card__JmBTW {
    background: #fff;
    margin: 20px auto;
    text-align: center;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 6px 6px #9b9b9b;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.Card_Empty__1l_UW {
    background: white;
    border: 1px solid red;
    box-shadow: 1px 3px 3px grey;
}

@media (min-width: 600px) {
    .Card_Card__JmBTW {
        width: 500px;
    }
}


.CloseIcon_Close__3HTEC {
  cursor: pointer;
  color: white;
  margin: 0;
  font-size: 20px;
  padding: 4px 8px;
}

.CloseIcon_Close__3HTEC:hover {
  color: black;
}


.DialogAppBar_Close__terO1 {
    border-radius: 4px;
    background: #f44336;
    padding: 0;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
}
.DialogNavigationItem_Container__3Ihwq {
    cursor: pointer;
    padding: 10px;
}

.DialogNavigationItem_Container__3Ihwq .DialogNavigationItem_Action__ExGQL {
    cursor: pointer;
    color: black;
    margin: 0;
    font-size: 20pt;
    padding: 10px;
    border-radius: 50%;
}

.DialogNavigationItem_Container__3Ihwq:hover {
    background: red;
    color: white;
}

.DialogNavigationItem_Container__3Ihwq:hover .DialogNavigationItem_Action__ExGQL {
    color: white;
}

.DialogNavigationItem_ContainerActive__Sgwyf {
    background: lightgrey;
}

.DialogNavigationItem_ContainerActive__Sgwyf:hover {
    background: black;
    color: white;
}

.DialogNavigationItem_ContainerActive__Sgwyf:hover .DialogNavigationItem_Action__ExGQL {
    color: white;
}

.DialogNavigationItem_Title__1T9Pm {
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;
}
.ColorPicker {
    background: #fff;
    text-align: center;
    padding: 10px 0;
}

.Label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;    color: rgba(0, 0, 0, 0.54);
    padding: 0 10px;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.00938em;
}


.color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 2;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@media (min-width: 500px) {
    .ColorPicker {
        width: 400px;
    }
}

a.Nav {
    text-decoration: none;
    color: transparent;
}

.Team {
    margin: 3px;
    padding: 2px;
    width: 33px;
    height: 33px;
}

.Team:hover {
    background: white;
}

.Active .Team {
    margin: 0 0 15px 0;
    padding: 0;
    width: 43px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Active .Team:hover {
    background: black;
}


.HeightInput {
    text-align: center;
    padding: 10px 0;
    display: flex;
}

.WeightInput {
    text-align: center;
    padding: 10px 0;
    display: flex;
}

.WeightInput div {
}

.TeamMemberPermissions_Container__3gdr1 {
    margin: 10px 0;
    padding: 10px 0;
    display: flex;
}

.TeamMemberPermissions_Row__1LI8X {
    flex: 1 1;
}

.TeamMemberPermissions_Cell__26ane {
    flex: 1 1;
}
.Table {
}

.Table th {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 3px;
}

.Table td {
    font-size: 0.7rem;
    margin: 0;
    padding: 3px;
}

.Close {
    border-radius: 4px;
    background: #f44336;
    padding: 0;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
}
.VideoFile_Container__nE9TR {
    position:relative;
}

.VideoFile_VideoInfo__1FWe_ {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 2147483647;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    color: white;
    display: flex;
    opacity: 0;
}

.VideoFile_VideoInfo__1FWe_ div {
    display: block;
    margin: 5px;
    padding: 5px;
    background: red;
    color: white;
}

.VideoFile_Container__nE9TR:-webkit-full-screen .VideoFile_VideoInfo__1FWe_ {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
}

.VideoFile_Container__nE9TR:-ms-fullscreen .VideoFile_VideoInfo__1FWe_ {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
}

.VideoFile_Container__nE9TR:fullscreen .VideoFile_VideoInfo__1FWe_ {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
}

.VideoFile_Container__nE9TR:-webkit-full-screen {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.VideoFile_Container__nE9TR:-ms-fullscreen {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.VideoFile_Container__nE9TR:fullscreen {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.VideoFile_Container__nE9TR:-webkit-full-screen .VideoFile_Video-Info__1_SyT:hover {
    background: rgba(0, 0, 0, 1);
    color: white;
}

.VideoFile_Container__nE9TR:-ms-fullscreen .VideoFile_Video-Info__1_SyT:hover {
    background: rgba(0, 0, 0, 1);
    color: white;
}

.VideoFile_Container__nE9TR:fullscreen .VideoFile_Video-Info__1_SyT:hover {
    background: rgba(0, 0, 0, 1);
    color: white;
}

.VideoFile_ProgressContainer__3gT3y {
    z-index: 999; /* 2147483647 */
    width: 100%;
    height: 35px;
    position: absolute;
    bottom:-31px;
    left:0;
    background: black;
}

.VideoFile_ProgressContainer2__3_UPC {
    z-index: 999; /* 2147483647 */
    width: 100%;
    height: 28px;
    position: absolute;
    bottom:-59px;
    left:0;
    background: black;
}

.VideoFile_Progress__1VbJ5 {
    z-index: 1000 !important; /* 2147483647 */
    border-radius: 0;
    cursor: pointer;
    background: black;
}

.VideoFile_ProgressBar__nC5AH {
    margin-top: 0;
    text-align: left;
    transition-duration: 0.05s;
    height: 35px;
    background-color: red;
}

.VideoFile_Controls__1p5rm {
    position: absolute;
    bottom: 0px;
    left: 0;
    text-align: left;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
}

.VideoFile_ControlIcons__2spMF {
    display:flex; 
    flex-grow: 0;
}

.VideoFile_VideoIndex__3QU7L {
    display:flex; 
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
}

.VideoFile_ControlTime__NwfD5 {
    display:flex; 
    justify-content: flex-end;
    align-items: center;
    padding: 0 5px;
    width: 150px;
}

.VideoFile_VideoIndex__3QU7L i, .VideoFile_ControlIcons__2spMF i {
    font-size: 20px;
}

.VideoFile_VideoIndex__3QU7L div, .VideoFile_ControlIcons__2spMF div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 8px 4px 8px;
}

.VideoFile_Figure__2a2lO {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.VideoFile_Video__2iZGN {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.VideoFile_Video__2iZGN::-webkit-media-controls {
    display: none !important;
}

.VideoFile_IconTextContainer__1sVYH {
    position: relative;
}

.VideoFile_IconText__2jITZ {
    font-size: 0.6rem;
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 8px;
}

.VideoFile_SelectedInput__2BQ-I {
    width: 25px;
    text-align: center;
}

.VideoFile_SelectedInput__2BQ-I:focus {
    outline: none;
}
.Header {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(0, 0, 0, .87);
    border-bottom: 1px solid red;
    padding-bottom: 5px;
}

.Styles_Buttons__fz3Px {
}

.Styles_Buttons__fz3Px Button {
    margin: 0 2px;
}

@media (min-width: 500px) {
    .Styles_ProfileItems__1barN {
        flex-flow: row;
    }
}

.Table {
}

.Table th {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 3px;
}

.Table td {
    font-size: 0.7rem;
    margin: 0;
    padding: 3px;
}

.Container {
    width: 100%;
    background: #828282;
    margin: 0 0 2px 0;
    padding: 0;
    cursor: pointer;
}

.Container:hover {
    background: #ffffff;
    margin: 0 0 0 0;
    border-bottom: 2px solid red;
}

.Container:hover div {
    color: red;
}

.Container div {
    width: 100%;
    margin: 0;
    padding: 10px;
    text-decoration: none;
    color: white;
}

.active {
    background: #ffffff;
    margin: 0 0 0 0;
    border-bottom: 2px solid red;
}

.active div {
    color: red;
}


.Error {
    width: 95%;
    border: 1px solid darkred;
    box-shadow: 0 2px 3px darkred;
    background: red;
    color: white;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
}

.Buttons {
    text-align: center;
}

.Buttons Button {
    margin-right: 3px;
}

.Buttons {
    text-align: center;
}

.Buttons Button {
    margin-right: 3px;
}

.Buttons {
    text-align: center;
}

.Buttons Button {
    margin-right: 3px;
}

.Outer {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.Inner {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    height: 1px;
    position: relative;
    padding: 0;
}

.AddTeam {
    text-align: center;
    margin: 3px;
    margin-top: 15px;
    cursor: pointer;
    padding: 2px;
    background-color: #757575;
    color: #F5F5F5;
}

.AddTeam:hover {
    background-color: #F5F5F5;
    color: #757575;
}

.SideNavItem_Container__1GvOJ {
    width: 100%;
    padding: 0;
}

.SideNavItem_Container__1GvOJ a {
    width: 100%;
    text-decoration: none;
    color: white;
    display: block;
}

.SideNavItem_Container__1GvOJ a:hover {
    background: white;
    color: black;
}

.SideNavItem_Container__1GvOJ a > div {
    width: 100%;
    text-decoration: none;
    padding: 5px;
}

.SideNavItem_Container__1GvOJ a.SideNavItem_active__2VKsJ {
    background: red;
    color: white;
}


.Season_Create__1-cRx {
    margin: 10px;
    padding: 0;
    cursor: pointer;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    border-radius: 4px;
    background: #fff;
    color: rgba(0, 0, 0, .87);
}

.Season_CreateContent__MVeIm {
    display: flex;
    padding: 0;
}

.Season_Triangle__T9R3_ {
    border: 54px solid transparent;
}

.Season_Menu__3dCh- ul {
    padding: 10px 0;
    background: #9E9E9E;
    width: 250px;
}

.Season_NavLink__QtJT9 {
    text-decoration: none;
}

.Season_NavLink__QtJT9 div {
    color: white;
    width: 100%;
    padding: 5px;
    margin: 0px;
    font-size: 0.7rem;
    border-radius: 3px;
}

.Season_NavLink__QtJT9 div:hover {
    color: #F5F5F5;
    background: red;
}

.Season_Active__2_w-W div {
    color: #F5F5F5;
    background: #757575;
}

.Season_Team__9YA0G {
    cursor: pointer;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold
}

.Season_Team__9YA0G:hover {
    color: #fff;
    background: red;
}

.Season_Season__3M6a7 {
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    padding: 3px 3px 3px 10px;
    margin-bottom: 0;
    font-size: 0.7rem;
}

.Season_Season__3M6a7:hover {
    color: #fff;
    background: red;
}

.Season_MenuContainer__Fad18 {
    position: relative;
}

.Season_SeasonMenu__1W-vo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2147483647;
    display: none;
    width: 0;
    height: 0;
    background: #555555;
    border: 2px blue;
    border-radius: 5px;
}

.Season_SeasonMenuActive__2Xp3A {
    display: block;
    width: 180px;
    height: auto;
    padding: 5px;
}

.Season_SeasonMenu__1W-vo .Season_NavLink__QtJT9 div, .Season_SeasonMenuActive__2Xp3A .Season_NavLink__QtJT9 div {
    margin-left: 0px;
    width: 170px;
}

.Season_Profile__288Us {
    background: #36454F;
    display: flex;
    padding: 5px;
}

.Season_Profile__288Us:hover {
    background: #414A4C;
}

.Season_Profile__288Us .Season_Link__3wYkr {
    width: 100%;
}

.Season_Profile__288Us .Season_Link__3wYkr:hover {
    color: red;
}

.Season_SubProfile__p-gyU {
    padding: 10px;
}

.Season_SubProfile__p-gyU .Season_Link__3wYkr {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}

.Season_SubProfile__p-gyU .Season_Link__3wYkr:hover {
    color: red;
}

.Season_ProfileName__1Jeqo {
    font-size: 0.8rem;
    font-style: normal;
}

.Season_ProfileUsername__PlqWG {
    font-size: 0.8rem;
    font-style: normal;
}

.Season_ProfileEmail__6y7Ql {
    font-size: 0.8rem;
    font-style: normal;
}

.Season_ProfileLink__17rsu {
    font-size: 0.6rem;
    font-style: normal;
}

.Season_ProfileImage__3DP-s {
    padding: 5px;
}

.Season_ProfileImage__3DP-s img {
    width: 40px;
    border-radius: 50%;
}

.Season_TeamContainer__3BZ_P {
    background: #000000;
    padding: 5px;
    margin-bottom: 5px;
    height: 55px;
}

.Season_TeamContainer__3BZ_P .Season_Link__3wYkr {
    width: 100%;
}

.Season_TeamContainer__3BZ_P .Season_Link__3wYkr:hover {
    color: red;
}

.Season_SubTeamContainer__XxfKV {
    padding: 10px;
}

.Season_SubTeamContainer__XxfKV .Season_Link__3wYkr {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}

.Season_SubTeamContainer__XxfKV .Season_Link__3wYkr:hover {
    color: red;
}

.Season_Container__1W3k- {
    cursor: pointer;
    display: flex;
    padding: 5px;
}

.Season_Container__1W3k- .Season_Link__3wYkr {
    width: 100%;
}

.Season_Name__18S3e {
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.Season_Username__3Qt3e {
    color: white;
    font-size: 0.8rem;
    font-style: italic;
    padding-left: 5px;
}

.Season_SeasonOverlay__tZEeU {
    position: absolute;
    top:0;
    left:0;
    background: red;
    opacity: 0.01;
    width: 100vw;
    height: 100vh;
    z-index: 500;
}
.Table th {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0;
    padding: 3px;
}

.Table td {
    font-size: 0.7rem;
    margin: 0;
    padding: 3px;
}

.Close {
    border-radius: 4px;
    background: #f44336;
    padding: 0;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
}
.Part_Part__2G5WB {
  font-size: 0.8rem;
}

.Part_Relative__2YT55 {
  position: relative;
  padding: 2px 0;
}

.Part_Flex__hehU9 {
  display: flex;
}

.Part_Tall__2S-HH {
  line-height: 30px;
}

.Part_Full__1B16P {
  width: 100%;
}

.Part_TitleCol__3w2Y- {
  text-align: left;
  flex-grow: 1;
  overflow: hidden;
}

.Part_SizeCol__1lpDi {
  flex: 0 0 50px;
  text-align: right;
}

.Part_ProgressCol__1wuGD,
.Part_StatusCol__2gCle {
  flex: 0 0 100px;
  text-align: right;
  padding-right: 5px;
}

.Part_StatusPending__3eZqk {
  color: blue;
}

.Part_StatusUploading__t0AMo,
.Part_StatusUploaded__R-MQ1,
.Part_StatusConverting__2INrb,
.Part_StatusImporting__24GuF {
  font-style: italic;
  color: green;
}

.Part_StatusFinished__9t4Pe {
  color: grey;
}

.Part_StatusError__VFYkr {
  color: red;
}

.Part_Progress__3mWpk {
  font-size: 12pt;
  background-color: blue;
}

.Part_Progress__3mWpk .Part_bar__1kIx7 {
  background-color: red;
  background-color: red;
}

.Part_RetryContainer__1FAm1 {
  width: 35px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Part_Retry__2fmSf {
  border-radius: 4px;
  background: #F44336;
  cursor: pointer;
  color: #FFFFFF;
  margin: 0;
  font-size: 14px;
  padding: 6px 10px;
}

.Part_Retry__2fmSf:hover {
  color: #212121;
  background: #D32F2F;
}


.Organize_Buttons__3SOKh {
    text-align: center;
}

.Organize_Buttons__3SOKh Button {
    margin-right: 3px;
}

.TeamDialog_Menu__2HIB7 {
    padding-right: 15px;
    display: flex;
    flex-direction: column;
}

.TeamDialog_Content__masRd {
    padding: 0 10px 0 0;
    border-right: 1px solid #BDBDBD;
    flex: 1 1 auto;
}

.TeamDialog_Legend__3EFnG {
    margin: 15px 0 0 0;
}

.TeamDialog_LegendRow__1COCw {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.TeamDialog_LegendColor__G6SoI {
    width: 20px;  
    margin-top: -3px;
    margin-left: 0;
}

.TeamDialog_LegendColor__G6SoI div {
    height: 10px;
    border-radius: 3px;
}

.TeamDialog_LegendLabel__PUstC {
    flex: 1 1;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 10px;
    font-size: 0.9rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.00938em;
}

.TeamDialog_LegendValue__3_b2M {
    width: 100px;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: rgba(0, 0, 0, 1);
    padding: 0;
    font-size: 0.9rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.00938em;
    text-align: right;
}
