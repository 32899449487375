a.Nav {
    text-decoration: none;
    color: transparent;
}

.Team {
    margin: 3px;
    padding: 2px;
    width: 33px;
    height: 33px;
}

.Team:hover {
    background: white;
}

.Active .Team {
    margin: 0 0 15px 0;
    padding: 0;
    width: 43px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Active .Team:hover {
    background: black;
}

