.Container {
    cursor: pointer;
    padding: 10px;
}

.Container .Action {
    cursor: pointer;
    color: black;
    margin: 0;
    font-size: 20pt;
    padding: 10px;
    border-radius: 50%;
}

.Container:hover {
    background: red;
    color: white;
}

.Container:hover .Action {
    color: white;
}

.ContainerActive {
    background: lightgrey;
}

.ContainerActive:hover {
    background: black;
    color: white;
}

.ContainerActive:hover .Action {
    color: white;
}

.Title {
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;
}