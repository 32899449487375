.Card {
    background: #fff;
    margin: 20px auto;
    text-align: center;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 6px 6px #9b9b9b;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.Empty {
    background: white;
    border: 1px solid red;
    box-shadow: 1px 3px 3px grey;
}

@media (min-width: 600px) {
    .Card {
        width: 500px;
    }
}
